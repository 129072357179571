import React from "react";
import * as s from "./education.module.less"
import {Divider} from "../divider";
import Img from "gatsby-image";
import {useTranslation} from "gatsby-plugin-react-i18next";
export const Education = ({
	educationData,
	certificateImages,
}) => {
	const {
		t
	} = useTranslation()
	return (
		<div className={ s.education }>
			<h2>{ t("cv.education") }</h2>
			<ul>
				{ educationData.map((edu) => {
					const certificate = certificateImages.edges.filter((elem) => elem.node.frontmatter.name === edu.title)[0]
					const title = certificate ? `${edu.time} | ${certificate.node.frontmatter.short.toUpperCase()}: ${edu.title}` : `${edu.time} | ${edu.title}`
					return (
						<>
							{ certificate && (
								<Img
									className={s.certificate}
									fluid={certificate.node.frontmatter.imagePath.childImageSharp.fluid}
								/>
							)}
							<li>{ title }
								 <span className={ s.description }>{ edu.description }</span>
							</li>
						</>
					)
				})}
			</ul>
			<Divider/>
		</div>
	)
}
