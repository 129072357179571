import React from "react";
import * as s from "./box.module.less"
import {Label} from "../label";
import SectorIcon from "../../../../media/icons/sector.png"
import EmployeesIcon from "../../../../media/icons/employees.png"
import LocationIcon from "../../../../media/icons/location.png"
import Img from "gatsby-image";
export const CareerSummaryBox = ({
	careerPoint,
	withQuickfacts,
	quickfacts
}) => {
	const {
		employer,
		time,
		project,
		description,
		labels
	} = careerPoint
	const imagePath = quickfacts?.imageDarkPath ?? quickfacts?.imagePath
	return (
		<div className={ s.box }>
			<hr/>
			<div className={ s.contentWrapper}>
				{ withQuickfacts && quickfacts  && (
					<div className={ s.quickfacts }>
						<div className={ s.quickfactsBox }>
							<img
								alt={"Location Icon"}
								src={LocationIcon}
							/>
							{ quickfacts.location }
						</div>
						<div className={ s.quickfactsBox }>
							<img
								alt={"Employees Icon"}
								src={EmployeesIcon}
							/>
							{ quickfacts.employees }
						</div>
						<div className={ s.quickfactsBox }>
							<img
								alt={"Sector Icon"}
								src={SectorIcon}
							/>
							{ quickfacts.sector }
						</div>
					</div>
				)}
				{ quickfacts && quickfacts.imagePath && (
					<div className={ s.logoWrapper }>
						{!imagePath.childImageSharp && imagePath.extension === 'svg'
							? (
								<img
									className={ s.logo }
									style={{objectFit: "contain"}}
									src={imagePath.publicURL}
									alt={project} />
							)
							: (
								<Img
									className={ s.logo }
									alt={project}
									durationFadeIn={0}
									fadeIn={false}
									loading={"eager"}
									fluid={imagePath?.childImageSharp?.fluid}
								/>
							)}
					</div>
				)}
				<div className={ s.content }>
					<div className={ s.header }>
						{ time }
						<span>{`${employer} | ${project}`}</span>
					</div>
					<div>
						{ description }
					</div>
					<div className={ s.labels }>
						{ labels.map((label) => {
							return(
								<Label
									title={ label }
								/>
							)
						}) }
					</div>
				</div>
			</div>
			<hr/>
		</div>
	)
}
