import React from "react"
import { graphql } from "gatsby";
import {useI18next} from "gatsby-plugin-react-i18next";
import * as s from './cv.module.less';
import {PersonalInformation} from "../../components/cv/personal-information";
import {Header} from "../../components/cv/header";
import {ProfessionalProfile} from "../../components/cv/professional-profile";
import {CoreSkills} from "../../components/cv/core-skills";
import {Education} from "../../components/cv/education";
import {CareerSummary} from "../../components/cv/career-summary";
import {Achievement} from "../../components/cv/achievement";
const CVTemplate = ({data}) => {
	const {
		cv,
		successStories,
		certificateImages
	} = data
	const {
		withQuickfacts,
		careerSummary,
		city,
		country,
		coreSkills,
		education,
		achievement,
		email,
		imagePath,
		jobTitle,
		name
	} = cv.edges[0].node.frontmatter
	const professionalProfileText = cv.edges[0].node.html
	const {
		language
	} = useI18next()
	return (
		<table
			className={ s.cvWrapper }
		>
			<thead>
				<Header/>
			</thead>
			<tbody className={ s.content}>
				<PersonalInformation
					name={ name }
					imagePath={ imagePath }
					jobTitle={ jobTitle }
					city={ city }
					country={ country }
					email={ email }
				/>
				<div className={ s.contentPane }>
					<ProfessionalProfile
						text={ professionalProfileText }
					/>
					<CoreSkills
						skills={ coreSkills }
					/>
					<Education
						educationData={ education }
						certificateImages={ certificateImages }
					/>
					{achievement && (
						<Achievement
							achievementData={ achievement }
						/>
					)}
					<CareerSummary
						careerSummary={ careerSummary }
						successStories={ successStories }
						withQuickfacts={ withQuickfacts }
					/>
				</div>
			</tbody>
		</table>
	)
}
export default CVTemplate
export const query = graphql`
    query($slug: String!, $language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        cv: allMarkdownRemark(
            filter: {
                fields: {
                    slug: { eq: $slug }
                    language: { eq: $language }
				}
                frontmatter: {
                    category: { eq: "cv" }
                    publish: { eq: true }
                }
            }
        ) {
			totalCount
            edges {
                node {
                    id
                    frontmatter {
						imagePath {
                            childImageSharp {
                                fluid(maxWidth: 200, maxHeight: 200) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        withQuickfacts
                        name
						jobTitle
						email
						city
						country
						coreSkills
						education {
							title
							time
							description
							isCertificate
						}
						achievement {
							title
							year
							rank
							prize
							description
						}
						careerSummary {
							employer
							time
							project
							title
							description
							labels
                        }
                    }
					html
                    fields {
                        slug
                    }
                }
            }
        }
        successStories: allMarkdownRemark(
            filter: {
                frontmatter: {
                    successStory: { eq: true }
                }
                fields: {
                    language: { eq: $language }
                }
            }

        ) {
            edges {
                node {
                    frontmatter {
                        companyName
                        location
                        employees
                        sector
                        imagePath {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                            extension
                            publicURL
                        }
                        imageDarkPath {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                            extension
                            publicURL
                        }
                    }
                    fields {
                        slug
                    }
                }
            }

        }
        certificateImages: allMarkdownRemark (
            sort: { fields: [frontmatter___order], order: ASC }
            filter: {
                frontmatter: {
                    isDraft: { ne: true }
                    category: { eq: "certificates" }
                }
            }
        ) {
            totalCount
            edges {
                node {
                    id
                    frontmatter {
                        name
                        short
                        url
                        imagePath {
                            childImageSharp {
                                fluid(maxWidth: 200) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`;
