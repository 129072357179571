import * as s from "./video.module.less";
import cn from "classnames";
import * as landingPageStyle from "../../landing-page.module.less";
import React from "react";

const Video = ({
	video,
	videoProps,
	videoErrormessage,
	isLarge,
	classnames
}) => {
	return (
		<div className={ cn(s.video, classnames, {
			[s.large]: isLarge
		}) }>
			<video
				{...videoProps}
			>
				<source
					src={ video }
					type={"video/webm"}
				/>
				<p className={ cn( landingPageStyle.description) }>
					{ videoErrormessage }
				</p>
			</video>
		</div>
	)
}
export default Video
