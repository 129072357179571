import React from "react"
import * as s from "./header.module.less"
import {Link} from "gatsby-plugin-react-i18next";
import logo from "../../../media/logo/tpx_s_orange.png";
export const Header = () => {
	return (
		<div className={s.header}>
			<Link to={`/`} className={s.logo}>
				<img
					alt={"Logo of TeamParallax"}
					src={logo}
				/>
			</Link>
			<hr className={ s.divider }/>
		</div>
	)
}
