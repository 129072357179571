import React from 'react'
import { Link } from 'gatsby'
import * as s from './button.module.less'
const Button = ({
	to,
	href,
	onClick,
	label,
	target,
	buttonType,
	rel,
	size,
	className,
	isDownload = false
}) => {
	let sizeClass
	let buttonClass
	switch (size) {
		case 'xs':
			sizeClass = s.extraSmallButton
			break
		case 's':
			sizeClass = s.smallButton
			break
		case 'l':
			sizeClass = s.largeButton
			break
		default:
			sizeClass = s.mediumButton
			break
	}
	switch (buttonType) {
		case 'secondary':
			buttonClass = s.secondaryButton
			break
		case 'tertiary':
			buttonClass = s.tertiaryButton
			break
		default:
			buttonClass = s.button
	}
	const finalButtonClass = `${buttonClass} ${sizeClass}`
	const internalLink = (
		<Link to={to} className={finalButtonClass}>
			{label}
		</Link>
	)
	const externalLink = (
		<a href={href} className={finalButtonClass} download={isDownload} target={target} rel={rel}>
			{label}
		</a>
	)
	const otherActionButton = (
		<button onClick={onClick} className={finalButtonClass}>
			{label}
		</button>
	)
	const getButton = () => {
		if (to) {
			return internalLink
		} else if (href) {
			return externalLink
		} else {
			return otherActionButton
		}
	}
	const button = s.btnWrapper
	return (
		<div className={ `${button} ${className}` }>
			{getButton()}
		</div>
)
}
export default Button
