import React from "react"
import { graphql } from "gatsby";
import {extractContentBetweenTags, splitStringBeforeTag} from "../../utils";
import {useI18next} from "gatsby-plugin-react-i18next";
import teamParallaxVideo from "../../media/videos/team_parallax.webm"
import AbstractLandingPage from "../../components/landing-page";
const LandingpageTemplate = ({data}) => {
	const {
		testimonials,
		landingpages,
		products
	} = data
	const {
		language
	} = useI18next()
	const landing = landingpages.edges[0].node.frontmatter
	const landingImg = landingpages.edges[0].node.frontmatter.headerImagePath.childImageSharp.fluid
	console.log("landingImg", landingImg);
	const productList = products.edges
	const testimonialList = testimonials.edges
	const landingContent = {
		heading: landing.heading,
		btn: {
			text: landing.btn.text,
			href: landing.btn.href,
		},
		description: <h2>
			<span>{ landing.description.text }</span>
			<ul>
				{ landing.description.list.map((elem) => (
						<li>{ elem }</li>
					)
				)}
			</ul>
		</h2>
	}
	const services = landing.portfolioIds
		.filter(portfolioId => productList.find(product => product.node.frontmatter.id === portfolioId))
		.map(portfolioId => {
		const item = productList.find(product => product.node.frontmatter.id === portfolioId)
		return {
			excerpt: item.node.excerpt,
			img: item.node.frontmatter.smallImagePath.childImageSharp.fluid.src,
			hideReadMore: item.node.frontmatter.hideReadMore,
			title: item.node.frontmatter.title,
			description: item.node.frontmatter.summary,
			btn: {
				text: "read more",
				href: `/${language}${item.node.fields.slug}`
			}
		}
	})
	const testimonialData =  testimonialList.filter(
		(testimonial) => {
			return landing.testimonialIds.includes(testimonial.node.frontmatter.id)
		}
	).map((item) => {
		return {
			img: item.node.frontmatter.imagePath.childImageSharp.fluid,
			name: item.node.frontmatter.name,
			company: item.node.frontmatter.company,
			description: extractContentBetweenTags(splitStringBeforeTag(item.node.html, "h2").slice(-1)[0], "p"),
			href: item.node.frontmatter.href
		}
	})
	return (
		<AbstractLandingPage
			services={services}
			disablePortfolioLink={landing.disablePortfolioLink}
			landingImg={landingImg}
			landingContent={landingContent}
			testimonials={testimonialData}
			video={ landing.videoPosition === "none"
				? undefined
				: teamParallaxVideo }
			certificates={undefined}
			isVideoOnLanding={
				landing.videoPosition === "top" ?? undefined
			}
		/>
	)
}
export default LandingpageTemplate
export const query = graphql`
    query($slug: String!, $language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        landingpages: allMarkdownRemark(
            filter: {
                fields: {
                    slug: { eq: $slug }
                    language: { eq: $language }
				}
                frontmatter: {
                    category: { eq: "landing" }
                    publish: { eq: true }
                }
            }
        ) {
			totalCount
            edges {
                node {
                    id
                    frontmatter {
						title
                        heading
                        headerImagePath {
                            childImageSharp {
                                fluid(maxWidth: 2000, maxHeight: 900) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        disablePortfolioLink
						videoPosition
						portfolioIds
                        testimonialIds
                        description {
                            text
                            list
                        }
                        btn {
                            text
                            href
                        }
                    }
                    fields {
                        slug
                    }
                    excerpt
                }
            }
        }
        products: allMarkdownRemark(
            filter: {
                fields: {
                    language: { eq: $language }
                }
                frontmatter: {
                    category: { eq: "portfolio" }
                }
            }
        ) {
            edges {
                node {
                    id
                    frontmatter {
						id
                        summary
                        title
						hideReadMore
                        smallImagePath {
                            childImageSharp {
                                fluid(maxWidth: 200, maxHeight: 200) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    fields {
                        slug
                    }
                    excerpt
                }
            }
        }
        testimonials: allMarkdownRemark(
            filter: {
                fields: {
                    language: { eq: $language }
                }
                frontmatter: {
                    category: {eq: "testimonial"}
                    publish: {eq: true }
                }
            }
        ) {
            edges {
                node {
                    html
                    fields {
                        language
                        slug
                    }
                    frontmatter {
						id
                        name
                        company
                        href
                        imagePath {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
