import React from 'react';
import {Helmet} from 'react-helmet';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import Img from 'gatsby-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { createVCardBlobUrl } from '../utils';


import * as PStyle from './profile.module.less';
import Technology from '../components/technology-stack';
import certificates from "../components/landing-page/certificates";
const socialMap = {
    twitter: "https://twitter.com/",
    github: "https://github.com/",
    gitlab: "https://gitlab.com/",
    linkedin: "https://linkedin.com/in/",
    xing: "https://xing.com/profile/",
}
const getSocialsForProfile = (frontmatter) => {
    /* get socials from frontmatter (defaults to undefined if not provided) */
    const {
        twitter = undefined,
        github = undefined,
        gitlab = undefined,
        xing = undefined,
        linkedin = undefined
    } = frontmatter
    /* socials object containing all socials for mapping */
    const socials = {twitter, github, gitlab, xing, linkedin}
    /* iterate over all socials and generate data-object for all configured social channels */
    return Object.entries(socials)
        .filter(([, v]) => v !== null)
        .map(([k, v]) => ({
            socialType: k,
            value: v,
            url: socialMap[k] + v
        }))
}
const ProfileTemplate = ({data}) => {
    const {
        profile,
        certificateImages,
        socialImages,
        profileTechnologies
    } = data
    const { t } = useTranslation()
    const frontmatter = profile.frontmatter;
    const socials = getSocialsForProfile(frontmatter);
    return (
        <Layout>
            <Helmet
                htmlAttributes={{
                    lang: "en",
                }}
            >
                <meta name="description" content={`Profile of ${frontmatter.name}`}/>
                <title> {frontmatter.name}</title>
            </Helmet>
            <div>
                <div hidden={!frontmatter.imagePath.childImageSharp.fluid} className={PStyle.profile}>
                    <h2> {frontmatter.name}</h2>
                    <a
                      href={createVCardBlobUrl(t, frontmatter.tel, frontmatter.name, frontmatter.dev_type, frontmatter.mobile)}
                      download={`${frontmatter.name.split(" ").join("-").toLowerCase()}.vcf`}
                    >
                      {t("downloadVCard")}
                    </a>
                    <div className={PStyle.profileHeader}>
                        <div className={PStyle.profileHeaderContent}>
                            <p
                                className={PStyle.expertise}
                                hidden={!frontmatter.expertise}
                            >
                                Roles:
                            </p>
                            <ul className={PStyle.expertiseList}>
                                {frontmatter.expertise.map((node) => (<li key={node}>{node}<br/></li>))}
                            </ul>
                            <p
                                className={PStyle.socials}
                                hidden={frontmatter.certificates <= 0}
                            >
                                Certificates:
                            </p>
                            <div
                                className={PStyle.certList}
                                hidden={frontmatter.certificates <= 0}
                            >
                                {frontmatter.certificates.length > 0 && (
                                    frontmatter.certificates.map((cert) => {
                                        const certification = certificateImages.edges
                                            .find((e) => e.node.frontmatter.short === cert).node.frontmatter
                                        return (
                                                <a
                                                    className={PStyle.cert}
                                                    href={certification.url}
													title={certification.short.toUpperCase()}
                                                    target={"_blank"}
                                                    rel="noopener noreferrer"
                                                >
                                                   <Img
                                                        alt={cert}
														title={certification.short.toUpperCase()}
                                                        fluid={certification.imagePath.childImageSharp.fluid}
                                                        className={PStyle.image}
                                                    />
                                                    <div className={PStyle.title}>
                                                        {certification.short.toUpperCase()}
                                                    </div>
                                                </a>
                                        )
                                        }
                                    )
                                )}
                            </div>
                            <p
                                className={PStyle.socials}
                                hidden={socials.length <= 0}
                            >
                                Socials:
                            </p>
                            <div
                                className={PStyle.socialsList}
                                hidden={socials.length <= 0}
                            >
                                {socials.length > 0 && (
                                    socials.map((social) => (
                                            <div>
                                                <a
                                                    href={social.url}
                                                    target={"_blank"}
                                                    rel="noopener noreferrer"
                                                >
                                                    <Img
                                                        alt={social.socialType}
                                                        fluid={
                                                            socialImages.edges
                                                                .find((e) => e.node.name === social.socialType)?.node.childImageSharp.fluid
                                                        }
                                                        className={PStyle.socialImage}
                                                    />
                                                </a>
                                            </div>
                                        )
                                    )
                                )}
                            </div>
                        </div>
                        <div className={PStyle.profileHeaderContent}>
                            <Img
                                className={PStyle.image}
                                alt={frontmatter.name}
                                durationFadeIn={0}
                                fadeIn={false}
                                loading={"eager"}
                                fluid={frontmatter.imagePath.childImageSharp.fluid}
                            />
                        </div>
                    </div>
                    <p hidden={!frontmatter.date}>Last changed: {frontmatter.date}</p>
                </div>
            </div>
            <div dangerouslySetInnerHTML={{__html: profile.html}}/>
            <div>
            </div>
            {
              frontmatter.technology && frontmatter.technology.length > 0 &&
                  <div>
                      <h2> Technology </h2>
                      <Technology
                          technologies={data.technology.edges}
                          personalStack={frontmatter.technology}
                          profileTechnologies={profileTechnologies.edges}
                          profile
                      />
                  </div>
            }
        </Layout>
    );
};
export default ProfileTemplate
export const query = graphql`
  query($slug: String!, $language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
            node {
                ns
                data
                language
            }
        }
    }
    profile: markdownRemark(
      frontmatter: {  category: { eq: "profile" }, isInactive: {ne: true}}
      fields: {
        slug: { eq: $slug } 
        language: { eq: $language } 
      }
    ) {
      html
      frontmatter {
        date(formatString: "DD MMMM, YYYY")
        author
        expertise
        name
        dev_type
        degree
        technology
        certificates
        twitter
        github
        gitlab
        xing
        tel
        mobile
        linkedin     
        imagePath {
            childImageSharp {
                fluid(maxWidth: 200, maxHeight: 200) {
                  ...GatsbyImageSharpFluid
                }
            }
        }
      }
      fields {
        slug
      }
    }
    profileTechnologies: allMarkdownRemark (
      filter: {
        frontmatter: {  category: { eq: "profile" }}
      }
    ) {
      edges {
        node {
          frontmatter {
            technology
          }
        }
      }
    }
    technology: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, absolutePath:{regex: "media/technology/"}}) {
      edges {
        node {
          id
          name
          relativePath
          childImageSharp {
            fluid(maxWidth: 100, maxHeight: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    socialImages: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, absolutePath:{regex: "media/socials/"}}) {
      edges {
        node {
          id
          name
          relativePath
          childImageSharp {
            fluid(maxWidth: 100, maxHeight: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
      certificateImages: allMarkdownRemark (
          sort: { fields: [frontmatter___order], order: ASC }
          filter: {
              frontmatter: {
                  isDraft: { ne: true }
                  category: { eq: "certificates" }
              }
          }
      ) {
          totalCount
          edges {
              node {
                  id
                  frontmatter {
                      name
                      short
                      url
                      imagePath {
                          childImageSharp {
                              fluid(maxWidth: 200) {
                                  ...GatsbyImageSharpFluid
                              }
                          }
                      }
                  }
                  fields {
                      slug
                  }
              }
          }
      }
  }
`;
