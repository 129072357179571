import React from "react";
import * as s from "./personal-information.module.less"
import Img from "gatsby-image";
import {Divider} from "../divider";
export const PersonalInformation = ({
	name,
	imagePath,
	jobTitle,
	city,
	country,
	email
}) => {
	const emailSplit = email.split("@")
	return (
		<div className={ s.personalInformation }>
			<Img
				className={ s.image }
				alt={name}
				durationFadeIn={0}
				fadeIn={false}
				loading={"eager"}
				fluid={imagePath?.childImageSharp?.fluid}
			/>
			<div className={ s.data }>
				<h1>{ name }</h1>
				<span>{ jobTitle }</span>
				<span>{ `${city}, ${country}` }</span>
				<span>
					{ emailSplit[0] }
					<span className={ s.mailAt }>@</span>
					{ emailSplit[1] }
				</span>
				<Divider/>
			</div>
		</div>
	)
}
