import React from "react"
import * as landingStyle from "./landing.module.less"
import officeImg from "../../media/landing-page/office.jpg"
import whoWeAreImg from "../../media/landing-page/how-we-are.png"
import automationIcon from "../../media/landing-page/automation-icon.png"
import modernizationIcon from "../../media/landing-page/modernization-icon.png"
import TwitterIcon from "../../media/svg/twitter-square.svg"
import {useI18next} from "gatsby-plugin-react-i18next";
import LinkedinIcon from "../../media/svg/linkedin-square.svg"
import Landing from "./landing-video"
import Services from "./services"
import WhyChoose from "./why-choose"
import Footer from "./footer"
import About from "./about"
import Help from "./help"
import Testimonials from "./testimonials"
import { graphql, StaticQuery } from "gatsby";
import CustomersOverview from "../customer-overview/customersOverview";
import { Helmet } from "react-helmet";
import Certificates from "./certificates";
import VideoPane from "./video-pane";
import Simplymeet from "./simplymeet";
import Header from "./header";
import Favicon from "../../media/favicon/x.ico";
const AbstractLandingPage = ({
   services,
   testimonials,
   landingContent,
   certificates,
   landingImg,
   isVideoOnLanding,
	disablePortfolioLink,
   video
}) => {
    const {
        language
    } = useI18next()
    const getNavElements = () => {
       return [
            {
                text: "Portfolio",
                href: "/portfolio"
            },
            {
                text: "Team",
                href: "/employees"
            },
            {
                text: "Blog",
                href: "/blog"
            },
            {
                text: "Success Stories",
                href: "/success-stories"
            }
        ]
    }
	const scriptContent = `
    (function(d, w, b, t){
      var h = d.getElementsByTagName("head")[0];
      var s = d.createElement("script");
      s.src = b + "/t/script.js?c=" + t;
      h.appendChild(s);
    })(document, window, "https://ltracking.de", "${process.env.UTM_ID}");
  `;
    return (
        <StaticQuery
            query={graphql`
		query {
		customers: allMarkdownRemark (
			sort: { fields: [frontmatter___order], order: ASC }
			filter: {
				frontmatter: { isDraft: { ne: true } category: { eq: "customers" }}
	  		}
		) {
			totalCount
				edges {
					node {
						id
						frontmatter {
							name
							link
							imagePath {
							childImageSharp {
								fluid(maxWidth: 200) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
					fields {
						slug
					}
				}
			}
		}
		generalInformation: allMarkdownRemark (
			sort: { 
			    fields: [frontmatter___order]
			}
			filter: {
				frontmatter: { 
                    isDraft: { ne: true } 
                    category: { eq: "general-information" }
				}
	  		}
		) {
			totalCount
				edges {
					node {
						id
						frontmatter {
							phone
							address
						}
					}
				}
			}
        successStory: allMarkdownRemark(
          filter: {
            frontmatter: {
              category: {eq: "successStory"}
              publish: { eq: true }
            }
          }) {
            edges {
              node {
                html
                fields {
                  language
                  slug
                }
                frontmatter {
                  companyName 
                  publish
                  imagePath {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
    `}
            render={ data => {
                const information = data.generalInformation.edges[0].node.frontmatter
                // const filteredSuccesStories = data.successStory.edges.filter(({node}) => node.fields.language === language && node.frontmatter.publish)
                return(
                    <div className={landingStyle.container}>
						<Helmet>
							<title>Für alle Unternehmer | Team Parallax</title>
							<link rel="icon" type="image/png" href={ Favicon } sizes="16x16"/>
							<script>{scriptContent}</script>
						</Helmet>
						<header>
							<Header
								navElements={ getNavElements() }
							/>
						</header>
						{ isVideoOnLanding
							? (
								<Landing
									video={video}
                                    videoProps={ {
                                        controls: true,
                                        muted: false
                                    } }
                                    isFullSize={ true }
                                    navElements={getNavElements()}
                                    backgroundImageUrl={ landingImg }
                                    headerBtn={ {
                                        text: "Contact",
                                        onClick: () => undefined,
                                        href: "/de/contact"
                                    } }
                                    content={ landingContent }
                                />
                            )
                            : (
                                <Landing
                                    navElements={getNavElements()}
                                    backgroundImageUrl={ landingImg }
                                    headerBtn={ {
                                        text: "Contact",
                                        onClick: () => undefined,
                                        href: "/de/contact"
                                    } }
                                    content={ landingContent }
                                />
                            )
                        }
                        { services && (
                            <Services
                                heading="WAS WIR ANBIETEN"
                                description="Wir arbeiten mit Unternehmen zusammen, um denkwürdige Erlebnisse zu schaffen."
                                services={services}
                                btn={ disablePortfolioLink
									? undefined
									: {
                                    text: "PORTFOLIO",
                                    href: "/de/portfolio"
                                } }
                            />
                        )}
                        <CustomersOverview
                            data={data.customers.edges}
                        />
                        { video && !isVideoOnLanding && (
                            <VideoPane
                                heading={"Kurz und Knapp"}
                                description={"Es braucht nicht lange, um einen Eindruck zu hinterlassen. In nur 90 Sekunden zeigen wir Ihnen, warum wir die richtige Wahl für Sie sind."}
                                video={ video }
                                videoProps={ {
                                    controls: true,
                                    muted: false
                                } }
                                isFullSize={ true }
                                videoErrormessage={ "Ihr Browser kann dieses Video nicht wiedergeben. Dieses Video zeigt den Big Buck Bunny Film." }
                                backgroundImage={ officeImg }
                            />
                        )}
                        <Help
                            heading="WIE WIR HELFEN"
                            description="Im Allgemeinen wollen wir, dass unsere Kunden mit unseren Dienstleistungen Geld verdienen oder sparen. Wir sind spezialisiert auf Automatisierung und Modernisierung - den Umbau alter Software in Webanwendungen."
                            services={ [
                                {
                                    icon: automationIcon,
                                    title: "AUTOMATION",
                                    description: "Damit unsere Kunden nicht viel Geld für unnötiges und ineffizientes Personal bezahlen müssen"
                                },
                                {
                                    icon: modernizationIcon,
                                    title: "MODERNISIERUNG",
                                    description: "Damit ihre alte Software in neuem Glanz erstrahlt (bessere UX, schneller, weniger Wartungsaufwand, von allen Geräten aus zugänglich usw.)"
                                }
                            ] }
                            text="Wir übernehmen die volle Verantwortung für den Erfolg unserer Kunden"
                        />
                        <WhyChoose
                            header={"Warum"}
                            teamName="TEAM PARALLAX"
                            description="Wir nutzen die modernsten Technologien, um Unternehmen bei der Skalierung ihrer Fähigkeiten in den Bereichen Entwicklung, Design und digitales Marketing zu unterstützen."
                            backgroundImage={ officeImg }
                        />
                        <About
                            heading="WER WIR SIND"
                            description1="Als junges IT-Unternehmen freuen wir uns darauf, Ihre Ideen umzusetzen und/oder sie gemeinsam mit Ihnen zu entwickeln."
                            description2="Wir lieben Herausforderungen!"
                            description3="Ob Web- oder mobile Anwendungen, E-Commerce, sicherheitsrelevante Anwendungen, Gamification, Effizienz- und Produktivitätssteigerungen - wir finden eine Lösung, die zu Ihrem Geschäft und Ihren Zielgruppen passt."
                            btn={ {
                                text: "MEET OUR TEAM",
                                onClick: () => undefined,
                                href: "/de/employees/"
                            } }
                            img={ whoWeAreImg }
                        />
                        { certificates && (
                            <Certificates
                                heading="Wir sind zertifiziert"
                                description="Unser Team verfügt über Zertifikate in den verschiedensten Bereichen um die beste Expertise für unsere Kunden zu haben."
                                certificates={ certificates } />
                        )}
                        { testimonials && (
                            <Testimonials
                                heading="TESTIMONIALS"
                                description="Das sagen unsere Kunden über uns"
                                testimonials={testimonials}
                            />
                        )}
                        <Simplymeet
                            heading={"LET’S GET STARTED"}
                            description={"Ein Projekt oder eine Partnerschaft ist geplant? Vereinbaren Sie direkt ihren Termin zu einem ersten Kennenlernen mit Team Parallax"}
                        />
                        {/*<Contact*/}
                        {/*    heading="Let's Get Started"*/}
                        {/*    description="Ein Projekt oder Partnerschaft geplant? Jetzt für eine gratis Beratung kontaktieren!"*/}
                        {/*    btn={ {*/}
                        {/*    title: "Kontakt",*/}
                        {/*    onClick: () => undefined,*/}
                        {/*    href: "/de/contact"*/}
                        {/*    } }*/}
                        {/*    img={ redTeam }*/}
                        {/*/>*/}
                        <Footer
                            socialIcons={ [
                                {
                                    icon: <LinkedinIcon/>,
                                    href: "https://www.linkedin.com/company/team-parallax"
                                },
                                {
                                    icon: <TwitterIcon/>,
                                    href: "https://twitter.com/InsOp_de"
                                }
                            ] }
                            links={ getNavElements() }
                            address={ information.address }
                            phone={ information.phone }
                        />

                    </div>
                )}}
        >

        </StaticQuery>
    )
}
export default AbstractLandingPage
