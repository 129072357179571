import React from "react";
import * as s from "./achievement.module.less"
import {Divider} from "../divider";
import {useTranslation} from "gatsby-plugin-react-i18next";
export const Achievement = ({
	achievementData
}) => {
	const {
		t
	} = useTranslation()
	return (
		<div className={ s.education }>
			<h2>{ t("cv.achievements") }</h2>
			<ul>
				{ achievementData.map((elem) => {
					return (
						<li>{ `${elem.year} | ${elem.title}` }
							 <span className={ s.description }>{ `${elem.rank} | ${elem.prize}` }</span>
						</li>
					)
				})}
			</ul>
			<Divider/>
		</div>
	)
}
