import React from "react"
import Service from "./service"
import cn from "classnames"
import * as s from "./services.module.less"
import * as landingPageStyle from "../landing-page.module.less"
import Pane from "../../pane/pane";
import PaneTitle from "../../pane/pane-title/pane-title";
import Button from "../../button";
import RoundedButton from "../../rounded-button";
const Services = ({
  heading,
  description,
  services,
  btn
}) => {
  return (
      <Pane>
          <div className={ cn(landingPageStyle.container, s.service) }>
            <PaneTitle
              title={ heading }
              description={ description }
              isCentered
            />
            <div className={ s.box }>
              {services.map((service) => (
                <div className={ s.serviceBox } key={ service.title }>
                  <Service service={ service }/>
                </div>
              ))}
            </div>
			  {btn && (<Button
				  label={btn.text}
				  href={btn.href}
				  size={"xs"}
			  />)}

          </div>
      </Pane>
  )
}
export default Services
