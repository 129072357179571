import React, { useState } from "react"
import cn from "classnames"
import * as s from "./landing-video.module.less";
import {useTranslation} from "gatsby-plugin-react-i18next";
import * as landingPageStyle from "../landing-page.module.less"
import Video from "../video-pane/video";
import RoundedButton from "../../rounded-button";
import Button from "../../button";
import BackgroundImage from "gatsby-background-image";
const LandingVideo = ({
	navElements,
	headerBtn,
	backgroundImageUrl,
	content,
	video,
	videoProps,
	videoErrormessage
}) => {
	const {
        t
    } = useTranslation()
	return (
		<BackgroundImage
			Tag="div"
			className={s.landing}
			fluid={backgroundImageUrl}
		>
			<div className={ landingPageStyle.container }>
				<div className={ cn(s.content, {
					[s.hasVideo]: video
				}) }>
					<div className={ cn(landingPageStyle.container, s.headerContainer) }>
						{video && <Video
							video={ video }
							videoProps={ videoProps }
							videoErrormessage={ videoErrormessage }
							isLarge={ false }
							classnames={ s.video }
						/>}
						<div className={ s.textContent }>
							<h1 className={ s.heading }>{ content.heading }</h1>
							<p className={ s.description }>{ content.description }</p>
							{ content.btn && (
								<Button
									className={ s.contentBtn }
									onClick={content.btn.onClick}
									href={ content.btn.href }
									label={ content.btn.text }
									size={"s"}
								/>
							)}
						</div>
					</div>

				</div>
			</div>
		</BackgroundImage>
	)
}
export default LandingVideo
