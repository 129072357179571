import React from "react";
import * as footerStyles from "./subfooter.module.less";
import { useLocation } from "@gatsbyjs/reach-router";
import { Typeform } from "./typeform";
import { getContactTypeformId } from "../utils";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import RoundedButton from "./rounded-button";
import Button from "./button";
const Subfooter = () => {
	const location = useLocation();
	const {
		t,
		i18n: {
			language
		}
	} = useTranslation()
	const customTypeFormId = getContactTypeformId(language)
	const discordInviteLink = "https://discord.gg/JrSg9t3qP7"
	return (
		<div
			hidden={ location.pathname === "/contact" }
			className={ footerStyles.subfooter }
		>
			<div className={ footerStyles.content }>
				<p className={ footerStyles.description }>
					{ t("footer.contact") }
				</p>
				<div className={footerStyles.buttons}>
					{
						customTypeFormId
							? (
								<Typeform
									typeformId={customTypeFormId}
									popUpText={t("button.contactButton")}
									className={footerStyles.contactButton}
								/>
							)
							: (
								<Button
									className={footerStyles.contactBtn}
									to={ `/contact` }
									label={ t("button.contactButton") }
									size={"s"}
								/>
							)
					}
					<Button
						label={t("joinDiscord")}
						href={discordInviteLink}
						target={"_blank"}
						rel={"nofollow"}
						buttonType={"tertiary"}
						size={"s"}
					/>
				</div>
			</div>
		</div>
	)
};
export default Subfooter
