import React from 'react'
import { Link } from 'gatsby'
import * as s from './rounded-button.module.less'
const RoundedButton = ({
	to,
	href,
	onClick,
	label,
	target,
	buttonType,
	rel,
	size,
	className,
	isDownload = false
}) => {
	let sizeClass
	let buttonClass
	switch (size) {
		case 'xs':
			sizeClass = s.extraSmallButton
			break
		case 's':
			sizeClass = s.smallButton
			break
		case 'l':
			sizeClass = s.largeButton
			break
		default:
			sizeClass = s.mediumButton
			break
  	}
	switch (buttonType) {
		case 'secondary':
			buttonClass = s.secondaryButton
			break
		case 'tertiary':
			buttonClass = s.tertiaryButton
			break
		default:
			buttonClass = s.button
	}
	const finalButtonClass = `${buttonClass} ${sizeClass} ${className}`
	const internalLink = (
		<Link to={to} className={finalButtonClass}>
		{label}
		</Link>
	)
	const externalLink = (
		<a href={href} className={finalButtonClass} download={isDownload} target={target} rel={rel}>
		{label}
		</a>
	)
	const otherActionButton = (
		<button onClick={onClick} className={finalButtonClass}>
		{label}
		</button>
	)
	if (to) {
		return internalLink
	} else if (href) {
		return externalLink
	}
	return otherActionButton
}
export default RoundedButton
