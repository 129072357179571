import * as s from "./simplymeet.module.less";
import * as landingPageStyle from "../landing-page.module.less";
import floImg from "../../../media/staff/Flo.png"
import stefanImg from "../../../media/staff/Stefan.png"
import philippImg from "../../../media/staff/Philipp.png"
import marcelImg from "../../../media/staff/Marcel.png"
import spImg from "../../../media/staff/SP.png"
import benImg from "../../../media/staff/Ben.png"
import vanessaImg from "../../../media/staff/Vanessa.png"
import React from "react";
import cn from "classnames"
import PaneTitle from "../../pane/pane-title/pane-title";
const Simplymeet = ({
	heading,
	description
}) => {
	return (
		<div className={ s.simplymeetWrapper } id={"simplymeet"}>
			<div className={ s.simplymeet }>
				<div className={ landingPageStyle.container }>
					<div
						className={ s.leftBox }
					>
					</div>
					<div className={ s.rightBox }>
					</div>
					<div className={ s.box }>
						<div className={ s.frameBox }>
							<iframe
								src="https://calendly.com/marcel-michelfelder-team-parallax/30min"
								scrolling="yes"
								frameBorder="0"
							></iframe>
						</div>
						<div className={ s.heading }>
							<PaneTitle
								title={heading}
								description={description}
								isWhite
							/>
						</div>
					</div>
				</div>
			</div>
			<div className={ s.staff } >
				<div className={ cn(s.staffBox, s.firstBox) }>
					<img
						src={ floImg }
						alt={ "Illustration of Flo" }
					/>
					<img
						src={ stefanImg }
						alt={ "Illustration of Stefan" }
					/>
				</div>
				<div className={ s.staffBox }>
					<img
						src={ philippImg }
						alt={ "Illustration of Philipp" }
					/>
					<img
						src={ marcelImg }
						alt={ "Illustration of Marcel" }
					/>
					<img
						src={ spImg }
						alt={ "Illustration of Sven-Patrick" }
					/>
					<img
						src={ benImg }
						alt={ "Illustration of Ben" }
					/>
					<img
						src={ vanessaImg }
						alt={ "Illustration of Vanessa" }
					/>
				</div>
			</div>
		</div>
	)
}
export default Simplymeet
