import React from "react";
import {Divider} from "../divider";
import {useTranslation} from "gatsby-plugin-react-i18next";
export const ProfessionalProfile = ({
	text
}) => {
	const {
		t
	} = useTranslation()
	return (
		<div>
			<h2>{ t("cv.professionalProfile")}</h2>
			<div dangerouslySetInnerHTML={{__html: text}}/>
			<Divider/>
		</div>
	)
}
