import React from "react";
import * as s from "./career-summary.module.less"
import {CareerSummaryBox} from "./box";
import {useTranslation} from "gatsby-plugin-react-i18next";
export const CareerSummary = ({
	careerSummary,
	successStories,
	withQuickfacts
}) => {
	const {
		t
	} = useTranslation()
	return (
		<div className={ s.careerSummary }>
			<h2>{ t("cv.careerSummary") }</h2>
			{ careerSummary.map((careerPoint) => {
				const quickfactsFilter = successStories.edges.filter((story) => story.node.frontmatter.companyName === careerPoint.title)
				const quickfacts = quickfactsFilter.length !== 0 ? quickfactsFilter[0].node.frontmatter : undefined
				return (
					<CareerSummaryBox
						careerPoint={ careerPoint }
						withQuickfacts={ withQuickfacts }
						quickfacts={ quickfacts }
					/>
				)
			})}
		</div>
	)
}
