import React from "react";
import * as s from "./label.module.less"
export const Label = ({
	title
}) => {
	return (
		<div className={ s.label }>
			{title}
		</div>
	)
}
