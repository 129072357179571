import React from "react";
import * as s from "./core-skills.module.less"
import {Divider} from "../divider";
import {useTranslation} from "gatsby-plugin-react-i18next";
export const CoreSkills = ({
	skills
}) => {
	const {
		t
	} = useTranslation()
	return (
		<div className={ s.coreSkills }>
			<h2>{ t("cv.coreSkills") }</h2>
			<ul className={ s.skills }>
			{ skills.map((skill) => {
				return (
					<li>{ skill }</li>
				)
			})}
			</ul>
			<Divider/>
		</div>
	)
}
